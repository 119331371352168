import { useMemo } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { DataGridPro } from '@mui/x-data-grid-pro'
import RiskMatrixEvaluatedData from './RiskMatrixEvaluatedData'
import RiskMatrixValueData from './RiskMatrixValueData'
import CustomGridTreeDataGroupingCell from '../../../components/common/CustomGridTreeDataGroupingCell'
import styles from './RiskMatrixResult.module.scss'

const RiskMatrixResult = ({ riskResult, country = 'BR' }) => {
  const { t } = useTranslation(['common', 'messages'])

  const categoryRows = useMemo(() => {
    const categories = riskResult.results.reduce((acc, item) => {
      acc[item.category] = acc[item.category] || { score: 0, isHit: false, category: item.category }

      if (item.isHit && item.score > acc[item.category].score) {
        acc[item.category] = item
      }

      return acc
    }, {})

    return Object.values(categories).map((item) => ({
      ...item,
      id: uuidv4(),
      isGroup: true,
      groupedBy: item.category,
    }))
  }, [riskResult.results])

  const [ruleRows, categoryErrors] = useMemo(() => {
    const categoriesWithError = new Set()
    const childrenRules = []
    const rules = riskResult.results
      .map((result) => {
        const id = result.id || uuidv4()

        if (Array.isArray(result.groupResults)) {
          result.groupResults.forEach((childRule) => {
            const childRuleId = childRule.id || uuidv4()
            childrenRules.push({
              ...childRule,
              id: childRuleId,
              groupedBy: `${result.category}/${result.combinator}/${childRuleId}`,
            })
          })
        }

        const groupedBy = `${result.category}/${id}`

        if (result.dataToEvaluate === null) {
          categoriesWithError.add(result.category)
        }

        return {
          ...result,
          id,
          groupedBy,
        }
      })
      .filter((result) => !result.combinator)

    return [[...rules, ...childrenRules], [...categoriesWithError]]
  }, [riskResult.results])

  const columns = [
    {
      field: 'dataToEvaluate',
      headerName: t('messages:riskmatrix.title.valueFound'),
      width: 250,
      renderCell: ({ row, rowNode, value }) => {
        if (rowNode.type === 'group') {
          return ''
        }

        const dataToEvaluateNotFound = value === null || value === undefined

        if (dataToEvaluateNotFound) {
          return <span>{t(`messages:riskmatrix.errors.dataToEvaluateNotFound`)}</span>
        }

        return <RiskMatrixEvaluatedData data={value} operator={row.operator} rawText={row.value} />
      },
    },
    {
      field: 'operator',
      headerName: t('messages:riskmatrix.title.operator'),
      width: 200,
      renderCell: ({ value, rowNode }) => {
        if (rowNode.type === 'group') {
          return ''
        }

        return !!value && t(`messages:riskmatrix.operator.${value}`)
      },
    },
    {
      field: 'value',
      headerName: t('messages:riskmatrix.title.value'),
      width: 250,
      renderCell: ({ row, rowNode, value }) => {
        if (rowNode.type === 'group') {
          return ''
        }

        return (
          <RiskMatrixValueData
            data={value}
            list={['in_custom_list', 'in_custom_list_composed'].includes(row.operator) ? row.list : undefined}
          />
        )
      },
    },
    { field: 'isHit', headerName: t('messages:riskmatrix.title.isHit'), width: 120 },
    { field: 'score', headerName: t('messages:riskmatrix.title.score'), width: 120 },
  ]

  return (
    <Box className={styles.container}>
      {categoryRows && ruleRows && (
        <DataGridPro
          treeData
          rows={[...categoryRows, ...ruleRows]}
          columns={columns}
          getTreeDataPath={(row) => row.groupedBy.split('/')}
          groupingColDef={{
            headerName: 'Category',
            renderCell: (params) => <CustomGridTreeDataGroupingCell country={country} {...params} />,
            width: 300,
          }}
          disableColumnMenu
          disableColumnSorting
          hideFooter={true}
          rowSelection={false}
          getRowClassName={({ row }) => {
            const classNames = new Set()

            if (row.isGroup && categoryErrors.includes(row.category)) {
              classNames.add(styles.rowError)
            }
            if (!row.isGroup && (row.dataToEvaluate === null || !row.operatorFound)) {
              classNames.add(styles.rowError)
            }

            if (row.isHit) {
              classNames.add(styles.rowHit)
            }

            return [...classNames].join(' ')
          }}
        />
      )}
    </Box>
  )
}

export default RiskMatrixResult
