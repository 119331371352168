export const customCombinators = [
  { name: 'and', label: 'AND' },
  { name: 'or', label: 'OR' },
  { name: 'none', label: '---' },
]

export const customOperators = [
  { name: 'includes', input: 'string' },
  { name: 'includes_any', input: 'list' },
  { name: 'includes_all', input: 'list' },
  { name: 'any_includes', input: 'string' },
  { name: 'any_not_includes', input: 'string' },
  { name: 'any_not_equals', input: 'string' },
  { name: 'any_includes_any', input: 'list' },
  { name: 'all_includes_all', input: 'list' },
  { name: 'max_unique_values', input: 'number' },
  { name: 'is', input: 'boolean' },
  { name: 'any_is_number', input: 'number' },
  { name: 'equals', input: 'string' },
  { name: 'equals_number', input: 'number' },
  { name: 'less_than_number', input: 'number' },
  { name: 'less_equal_than_number', input: 'number' },
  { name: 'greater_than_number', input: 'number' },
  { name: 'greater_equal_than_number', input: 'number' },
  { name: 'between_inclusive_numbers', input: 'number_range' },
  { name: 'between_exclusive_numbers', input: 'number_range' },
  { name: 'is_size_description', input: 'list_sizes_descriptions' },
  { name: 'in_custom_list', input: 'custom_list' },
  { name: 'in_custom_list_composed', input: 'custom_list_composed' },
  { name: 'in_list', input: 'list' },
  { name: 'any_greater_than_number', input: 'number' },
  { name: 'any_greater_equal_than_number', input: 'number' },
  { name: 'any_is', input: 'boolean' },
  { name: 'any_is_status', input: 'list_status' },
  { name: 'any_in_sanctions', input: 'list_sanctions_source' },
  { name: 'exists', input: 'boolean' },
  { name: 'is_employment_status', input: 'list_employment_status' },
]

export const riskMatrixAttributes = {
  KYB: {
    AR: [
      {
        name: 'company_activities_code',
        operators: ['any_includes_any', 'any_includes'],
      },
      {
        name: 'company_activities_description',
        operators: ['any_includes_any', 'any_includes'],
      },
      {
        name: 'company_business_segments',
        operators: ['any_includes', 'equals'],
      },
      {
        name: 'company_city',
        operators: ['in_custom_list'],
        valueEditorType: 'select',
      },
      {
        name: 'company_country',
        operators: ['in_custom_list'],
      },
      {
        name: 'company_cluster',
        operators: ['includes', 'equals'],
      },
      {
        name: 'registration_uptime_months',
        operators: [
          'less_than_number',
          'less_equal_than_number',
          'greater_than_number',
          'greater_equal_than_number',
          'between_inclusive_numbers',
          'between_exclusive_numbers',
        ],
        inputType: 'number',
      },
      {
        name: 'shareholders_qty',
        operators: [
          'less_than_number',
          'less_equal_than_number',
          'greater_than_number',
          'greater_equal_than_number',
          'between_inclusive_numbers',
          'between_exclusive_numbers',
        ],
        inputType: 'number',
      },
      {
        name: 'shareholders_pep_qty',
        operators: [
          'less_than_number',
          'less_equal_than_number',
          'greater_than_number',
          'greater_equal_than_number',
          'between_inclusive_numbers',
          'between_exclusive_numbers',
        ],
        inputType: 'number',
      },
    ],
    BR: [
      {
        name: 'category_types',
        operators: ['any_includes', 'any_includes_any', 'max_unique_values'],
      },
      {
        name: 'category_descriptions',
        operators: ['any_includes', 'any_includes_any'],
      },
      {
        name: 'category_secondary_types_distint',
        operators: ['equals_number', 'greater_equal_than_number'],
      },
      {
        name: 'is_currently_pep',
        operators: ['is'],
        valueEditorType: 'select',
        values: [
          { name: 'true', value: true },
          { name: 'false', value: false },
        ],
      },
      {
        name: 'last_beneficiaries_pep_levels',
        operators: ['any_is_number'],
      },
      {
        name: 'last_beneficiaries_pep_history_times',
        operators: ['any_greater_than_number', 'any_greater_equal_than_number'],
      },
      {
        name: 'last_beneficiaries_japs',
        operators: ['any_includes_any'],
      },
      {
        name: 'last_beneficiaries_registration_status_names',
        operators: ['any_not_includes', 'any_not_equals'],
      },
      {
        name: 'legal_organization_description',
        operators: ['equals', 'includes'],
      },
      {
        name: 'size_description',
        operators: ['is_size_description', 'in_list'],
      },
      {
        name: 'registration_uptime_months',
        operators: [
          'less_than_number',
          'less_equal_than_number',
          'greater_than_number',
          'greater_equal_than_number',
          'between_inclusive_numbers',
          'between_exclusive_numbers',
        ],
        inputType: 'number',
      },
      {
        name: 'company_city',
        operators: ['in_custom_list'],
      },
      {
        name: 'company_country',
        operators: ['in_custom_list'],
      },
      {
        name: 'company_cluster',
        operators: ['includes', 'equals'],
      },
      {
        name: 'company_business_segments',
        operators: ['any_includes_any', 'any_includes'],
      },
      {
        name: 'company_client_types',
        operators: ['any_includes_any', 'any_includes'],
      },
      {
        name: 'company_history_sanctions',
        operators: ['any_in_sanctions', 'any_includes_any', 'any_includes', 'in_custom_list'],
        valueEditorType: 'select',
      },
      {
        name: 'relationships_business_qty_no_qsa',
        operators: ['greater_than_number', 'greater_equal_than_number'],
      },
      {
        name: 'shareholders_person_administrator_active',
        operators: ['any_greater_than_number', 'any_greater_equal_than_number'],
      },
      {
        name: 'shareholders_business_qty_no_active',
        operators: ['greater_than_number', 'greater_equal_than_number'],
      },
      {
        name: 'shareholders_business_with_shareholder_business',
        operators: ['less_than_number', 'less_equal_than_number', 'greater_than_number', 'greater_equal_than_number'],
      },
      {
        name: 'shareholders_business_with_all_shareholders_persons',
        operators: ['less_than_number', 'less_equal_than_number', 'greater_than_number', 'greater_equal_than_number'],
      },
      {
        name: 'company_is_mei',
        operators: ['is'],
      },
      {
        name: 'last_beneficiaries_history_sanctions',
        operators: ['any_in_sanctions', 'any_includes_any', 'any_includes', 'in_custom_list'],
      },
      {
        name: 'situation',
        operators: ['equals', 'not_equals'],
      },
      {
        name: 'shareholders_status',
        operators: ['any_is_status'],
      },
      {
        name: 'total_share_capital',
        operators: [
          'less_than_number',
          'less_equal_than_number',
          'greater_than_number',
          'greater_equal_than_number',
          'between_inclusive_numbers',
          'between_exclusive_numbers',
        ],
      },
      {
        name: 'negative_media',
        operators: ['exists'],
      },
      {
        name: 'protestos',
        operators: ['exists'],
      },
    ],
    CR: [
      {
        name: 'company_activity_name',
        operators: ['equals', 'in_custom_list'],
      },
      {
        name: 'company_document_type',
        operators: ['equals', 'includes', 'in_list'],
      },
      {
        name: 'legal_representative_document_type',
        operators: ['equals', 'includes', 'in_list'],
      },
      {
        name: 'legal_representative_country_of_birth',
        operators: ['equals', 'in_custom_list'],
      },
      {
        name: 'legal_representative_nationality',
        operators: ['equals', 'in_custom_list'],
      },
      {
        name: 'company_income_name',
        operators: ['equals', 'in_custom_list'],
      },
      {
        name: 'legal_representative_address_state_city',
        operators: ['in_custom_list_composed'],
      },
      {
        name: 'company_transactional_amount_name',
        operators: ['equals', 'in_custom_list'],
      },
      {
        name: 'is_currently_pep',
        operators: ['is'],
      },
      {
        name: 'is_reporting_entity',
        operators: ['is'],
      },
      {
        name: 'address_social_state_city',
        operators: ['in_custom_list_composed'],
      },
      {
        name: 'address_commercial_state_city',
        operators: ['in_custom_list_composed'],
      },
    ],
  },
  files_person: {
    AR: [
      { name: 'province', operators: ['in_custom_list'] },
      { name: 'main_activity', operators: ['includes_any'] },
      { name: 'is_pep', operators: ['is'], input: 'list' },
      { name: 'employment_status', operators: ['is_employment_status'] },
    ],
    BR: [],
  },
}
