import { Box, MenuItem, Select, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFindAllListsQuery } from '../../../../../features/lists/listsSlice'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'
import SelectInput from '../SelectInput'

const SelectListInput = ({ type = 'neutral', context = 'riskMatrix', isComposed = false, ...props }) => {
  const { emailClient } = useSelector((state) => state.login)

  const { data, isFetching, isLoading } = useFindAllListsQuery({
    emailClient,
    type,
    context,
  })

  const options = useMemo(() => {
    if (Array.isArray(data?.data)) {
      const items = data.data.filter((i) => (isComposed ? !!i.isComposed : !i.isComposed))
      return items?.map((item) => ({
        label: (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>{item.title}</Typography>
            <RenderTooltip title={<Typography variant="h5">{item.description}</Typography>} />
          </Box>
        ),
        value: item.id,
      }))
    }
    return []
  }, [data?.data, isComposed])

  if (isFetching || isLoading) {
    return (
      <Select displayEmpty options={[]} value="">
        <MenuItem disabled value="">
          <Typography variant="subtitle3">Loading...</Typography>
        </MenuItem>
      </Select>
    )
  }

  return <SelectInput options={options} {...props} />
}

export default SelectListInput
