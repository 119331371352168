import { Box, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'

import { kybStatusEnum } from '../../../../kyb/kyb.enums'
import { customOperators } from '../../constantsV2'
import SelectInput from '../SelectInput'
import TextInput from '../TextInput'
import TagsInput from '../TagsInput'
import SelectListInput from '../SelectListInput'
import RangeNumberInput from '../RangeNumberInput'

const CATEGORIES = [
  { label: 'Clientes', value: 'Clientes' },
  { label: 'Segmentos', value: 'Segmentos' },
  { label: 'CNAE Primário', value: 'CNAE Primário' },
  { label: 'CNAE Seções', value: 'CNAE Seções' },
  { label: 'Geografia', value: 'Geografia' },
  { label: 'Porte', value: 'Porte' },
  { label: 'Idade', value: 'Idade' },
  { label: 'Relacionamento', value: 'Relacionamento' },
  { label: 'PEP', value: 'PEP' },
  { label: 'Listas', value: 'Listas' },
  { label: 'Processos judiciais', value: 'Processos judiciais' },
  { label: 'Estrutura', value: 'Estrutura' },
  { label: 'Representante legal', value: 'legalRepresentative' },
  { label: 'Social address', value: 'socialAddress' },
  { label: 'Commercial address', value: 'commercialAddress' },
]

const CustomRule = (props) => {
  const { t } = useTranslation(['common', 'messages'])
  const { rule, actions, schema, path, country } = props

  const handleExtraChange = (key, value) => {
    actions.onPropChange(key, value, path)
  }

  const handleOperatorChange = (value) => {
    handleExtraChange('operator', value)
    handleExtraChange('value', '')
  }

  const fieldData = schema.fieldMap?.[rule.attribute] || {}
  const operatorData = customOperators.find((op) => op.name === rule.operator) || {}
  const valueEditorType = operatorData.input

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box width={path.length < 2 ? '82%' : '92%'} display="flex" justifyContent="space-between" alignItems="center">
        {path.length < 2 && (
          <Box width="16%" maxWidth="16%">
            <SelectInput
              value={rule.category || ''}
              onChange={(value) => handleExtraChange('category', value)}
              options={CATEGORIES.map((category) => ({ label: category.label, value: category.value }))}
              emptyLabel="Select Category"
              required
            />
          </Box>
        )}

        <Box width={path.length < 2 ? '30%' : '35%'} maxWidth={path.length < 2 ? '30%' : '35%'}>
          <SelectInput
            value={rule.attribute || ''}
            onChange={(value) => handleExtraChange('attribute', value)}
            options={schema.fields.map((attribute) => ({
              label: t(`messages:riskmatrix.attribute.${country}.${attribute.name}`),
              value: attribute.name,
            }))}
            emptyLabel="Select Attribute"
            required
          />
        </Box>

        <Box width={path.length < 2 ? '20%' : '25%'} maxWidth={path.length < 2 ? '20%' : '25%'}>
          <SelectInput
            value={rule.operator || ''}
            onChange={handleOperatorChange}
            options={(fieldData.operators || []).map((operator) => ({
              label: t(`messages:riskmatrix.operator.${operator}`),
              value: operator,
            }))}
            emptyLabel="Select Operator"
            disabled={!rule.attribute}
            required
          />
        </Box>

        <Box width={path.length < 2 ? '25%' : '30%'} maxWidth={path.length < 2 ? '25%' : '30%'}>
          {(!valueEditorType || valueEditorType === 'string') && (
            <TextInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
            />
          )}
          {valueEditorType === 'list' && (
            <TagsInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'custom_list' && (
            <SelectListInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              type="neutral"
              context="riskMatrix"
              required
            />
          )}
          {valueEditorType === 'custom_list_composed' && (
            <SelectListInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              type="neutral"
              context="riskMatrix"
              isComposed
              required
            />
          )}
          {valueEditorType === 'number' && (
            <TextInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              type="number"
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'number_range' && (
            <RangeNumberInput value={rule.value} onChange={(value) => handleExtraChange('value', value)} required />
          )}
          {valueEditorType === 'boolean' && (
            <SelectInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              options={[
                { label: 'True', value: true },
                { label: 'False', value: false },
              ]}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'list_sizes_descriptions' && (
            <SelectInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              options={['Micro Empresa', 'Empresa de Pequeno Porte', 'Demais', 'Não informado'].map((size) => ({
                label: size,
                value: size,
              }))}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'list_sanctions_source' && (
            <TagsInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              options={[
                'interpol',
                'fbi',
                'ofac',
                'uk',
                'eu',
                'unsc',
                'cepim',
                'ceis',
                'MTE',
                'cnep',
                'United Nations Security Council',
                'Ibama',
                'Securities and Exchange Commission',
                'Court of Auditors of the State of São Paulo',
                'National Council of Justice',
                'Federal Court of Auditors',
                'BSM Supervision',
                'Bank Hindered Suppliers',
                'ca-sanctions',
              ]}
              placeholder={`${t('common:select')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'list_status' && (
            <SelectInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              options={kybStatusEnum.map((status) => ({
                label: t(`common:${status}`),
                value: status,
              }))}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
          {valueEditorType === 'list_employment_status' && (
            <SelectInput
              value={rule.value}
              onChange={(value) => handleExtraChange('value', value)}
              options={[
                { label: 'Jubilado', value: 'retired' },
                { label: 'Empleado', value: 'employee' },
                { label: 'Monotributista', value: 'monotax' },
                { label: 'Autonomo', value: 'selfEmployed' },
                { label: 'Desempleado', value: 'unemployed' },
              ]}
              placeholder={`${t('common:insert')} ${t('common:value')}`}
              required
            />
          )}
        </Box>
      </Box>
      <Box width={path.length < 2 ? '15%' : '5%'} display="flex" gap="8px" alignItems="center">
        {path.length < 2 && (
          <TextInput
            value={rule.score || ''}
            onChange={(value) => handleExtraChange('score', Number(value))}
            type="number"
            placeholder={t('common:score')}
            required
          />
        )}
        <IconButton onClick={() => actions.onRuleRemove(path)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default CustomRule
