import { Box, styled, Typography } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Highlighter from 'react-highlight-words'
import { useEffect, useMemo, useRef, useState } from 'react'

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

const RiskMatrixEvaluatedData = ({ data, operator, rawText = '' }) => {
  const componentValueRef = useRef(null)
  const [isOverflowText, setIsOverflowText] = useState(false)

  const dataIsObject = useMemo(() => typeof data === 'object' && data !== null, [data])

  const text = useMemo(() => {
    if (Array.isArray(data)) {
      return `[${data?.join(', ')}]`
    }

    if (dataIsObject) {
      return `${JSON.stringify(data)}`
    }

    return `${data}`
  }, [data, dataIsObject])

  const highlights = useMemo(() => {
    if (operator === 'any_includes_any' && Array.isArray(rawText)) {
      return rawText
    } else if (operator === 'any_includes' && typeof value === 'string') {
      return [rawText]
    }

    return []
  }, [operator, rawText])

  const tooltipText = useMemo(() => {
    if (!isOverflowText || dataIsObject) {
      return ''
    }

    if (Array.isArray(data)) {
      return (
        <Box>
          {data.map((t) => (
            <Typography key={t}>
              - <Highlighter searchWords={highlights} textToHighlight={`${t}`} />
            </Typography>
          ))}
        </Box>
      )
    }
    return data
  }, [data, dataIsObject, highlights, isOverflowText])

  useEffect(() => {
    if (componentValueRef.current) {
      const { offsetHeight, scrollHeight, offsetWidth, scrollWidth } = componentValueRef.current
      setIsOverflowText(offsetHeight < scrollHeight || offsetWidth < scrollWidth)
    }
  }, [text])

  return (
    <CustomWidthTooltip title={tooltipText}>
      <span ref={componentValueRef} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </span>
    </CustomWidthTooltip>
  )
}

export default RiskMatrixEvaluatedData
