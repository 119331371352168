import { Box, Button, Divider, IconButton, Paper, Typography } from '@mui/material'
import TextInput from '../TextInput'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SelectInput from '../SelectInput'
import CustomRule from '../CustomRule'
import { useTranslation } from 'react-i18next'
import { defaultCombinators } from 'react-querybuilder'

const CATEGORIES = [
  { label: 'Clientes', value: 'Clientes' },
  { label: 'Segmentos', value: 'Segmentos' },
  { label: 'CNAE Primário', value: 'CNAE Primário' },
  { label: 'CNAE Seções', value: 'CNAE Seções' },
  { label: 'Geografia', value: 'Geografia' },
  { label: 'Porte', value: 'Porte' },
  { label: 'Idade', value: 'Idade' },
  { label: 'Relacionamento', value: 'Relacionamento' },
  { label: 'PEP', value: 'PEP' },
  { label: 'Listas', value: 'Listas' },
  { label: 'Processos judiciais', value: 'Processos judiciais' },
  { label: 'Estrutura', value: 'Estrutura' },
  { label: 'Representante legal', value: 'legalRepresentative' },
  { label: 'Social address', value: 'socialAddress' },
  { label: 'Commercial address', value: 'commercialAddress' },
]

const CustomGroup = (props) => {
  const { t } = useTranslation(['common', 'messages'])
  const { ruleGroup, actions, path, country } = props

  const handleGroupExtraChange = (key, value) => {
    actions.onPropChange(key, value, path)
  }

  const isRootGroup = path.length === 0

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {!isRootGroup && (
        <Box width="13%" maxWidth="13%">
          <SelectInput
            value={ruleGroup.category || ''}
            onChange={(value) => handleGroupExtraChange('category', value)}
            options={CATEGORIES.map((category) => ({ label: category.label, value: category.value }))}
            emptyLabel="Select Category"
            required
          />
        </Box>
      )}
      <Paper
        elevation={2}
        sx={{ width: isRootGroup ? '100%' : '66%', padding: 2, marginBottom: 2, border: '1px solid #ccc' }}
      >
        <Box display="flex" alignItems="center" gap="16px">
          {!isRootGroup && (
            <SelectInput
              value={ruleGroup.combinator}
              onChange={(value) => handleGroupExtraChange('combinator', value)}
              options={defaultCombinators}
              disabled={isRootGroup}
            />
          )}

          <Button
            variant="outlined"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => actions.onRuleAdd({ attribute: '', operator: '', value: '' }, path)}
          >
            Add Rule
          </Button>

          {isRootGroup && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                actions.onGroupAdd(
                  {
                    combinator: 'and',
                    rules: [],
                  },
                  path,
                )
              }
            >
              Add Group
            </Button>
          )}
        </Box>

        <Divider sx={{ marginY: 2 }} />

        {isRootGroup && (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box width="82%" display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography>{t('common:category')}</Typography>
                </Box>
                <Box width="30%">
                  <Typography>{t('common:attribute')}</Typography>
                </Box>
                <Box width="20%">
                  <Typography>{t('common:operator')}</Typography>
                </Box>
                <Box width="20%">
                  <Typography>{t('common:value')}</Typography>
                </Box>
              </Box>
              <Box width="10%">
                <Typography>{t('common:score')}</Typography>
              </Box>
            </Box>
            <Divider sx={{ marginY: 2 }} />
          </>
        )}

        <Box display="flex" flexDirection="column" gap="16px">
          {ruleGroup.rules.map((ruleOrGroup, index) =>
            ruleOrGroup.rules ? (
              <CustomGroup
                {...props}
                key={`group-${path.join('-')}-${index}`}
                path={[...path, index]}
                ruleGroup={ruleOrGroup}
                country={country}
              />
            ) : (
              <CustomRule
                {...props}
                key={`rule-${path.join('-')}-${index}`}
                path={[...path, index]}
                rule={ruleOrGroup}
                country={country}
              />
            ),
          )}
        </Box>
      </Paper>
      {!isRootGroup && (
        <Box width="15%" display="flex" gap="8px" alignItems="center">
          <TextInput
            value={ruleGroup.score || ''}
            onChange={(value) => handleGroupExtraChange('score', Number(value))}
            type="number"
            placeholder={t('common:score')}
            required
          />
          <IconButton onClick={() => actions.onGroupRemove(path)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default CustomGroup
